import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import { Button, Card, Modal } from "react-bootstrap";
import { Smartphone } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";
import CommonTable from "../tables/CommonTable";
import useSrlHelper from "../../hooks/useSrlHelper";

const DeviceApp = (props) => {
    const { getAppReady, id, type, data } = props;
    const [app, setApp] = useState([]);
    const { user } = useAuth();
    const [showModal, setShow] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [curId, setCurrent] = useState(null);
    const notify = useContext(NotyfContext);
    const childRef = useRef();
    const isUnmount = useRef(false);
    const { isSrl } = useSrlHelper();

    useEffect(() => {
        if (!id) {
            return;
        }
        getApp();
    }, [id]);

    useEffect(() => {
        return () => { isUnmount.current = true }
    }, []);

    const getApp = async () => {
        try {
            var result = await api.get(`${type}/apps/${id}`);
            if(isUnmount.current) return;
            setApp(result.data);
            getAppReady && getAppReady([result.data]);
        } catch (error) {
            if(isUnmount.current) return;
            setApp(null);
            getAppReady && getAppReady([]);
        }
    };

    const appLink = () => { 
        setShow(true);
    };

    const appUnlink = (appId) => {
        setCurrent(appId);
        setShow(true);
     };

    const columns = [
        {
            Header: "",
            accessor: "id",
            minWidth: 60,
            disableSortBy: true,
            Cell: cell => (
                <Button
                    variant={cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "danger" : "success"}
                    onClick={() => onOperate(cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "warn" : "edit", cell.value, cell.row.original)}>Link</Button>
            )
        },
        {
            Header: "#Serial",
            accessor: "serial"
        },
        {
            Header: "Model",
            accessor: "model",
            disableSortBy: true,
        },
        {
            Header: "Last Seen",
            accessor: "lastReport",
            type: "dateFromNow"
        },
        {
            Header: "Linked",
            accessor: "elementId",
            type: "linkStatus",
            disableSortBy: true
        }
    ];

    const onOperate = (name, chooseId, data) => {
        if (app) {
            api.post(`apps/${app.id}/unlink/${id}`, { params: { parentId: id, id: app.id } })
                .then(res => {
                    notify.open({
                        type: "success",
                        message: "Changes Saved"
                    });
                    getApp();
                }).catch(e => {
                    notify.open({
                        type: "error",
                        message: e
                    });
                });
            setCurrent(null);
        } else {
            if (name === "edit") {
                api.post(`apps/${chooseId}/link/${id}`, { params: { parentId: id, id: curId } })
                    .then(res => {
                        notify.open({
                            type: "success",
                            message: "Changes Saved"
                        });
                        getApp();
                    }).catch(e => {
                        notify.open({
                            type: "error",
                            message: e
                        });
                    });
                setCurrent(null);
            } else if (name === "warn") {
                setShowWarning(data);
            } else if (name === "relink") {
                api.post(`apps/${chooseId?.id}/unlink/${chooseId?.assetId || chooseId?.personId || chooseId?.zoneId}`)
                    .then(res => {
                        api.post(`apps/${chooseId?.id}/link/${id}`, { params: { parentId: id, id: curId } })
                            .then(res => {
                                notify.open({
                                    type: "success",
                                    message: "Changes Saved"
                                });
                                getApp();
                                setShowWarning(null);
                            });
                    }).catch(e => {
                        notify.open({
                            type: "error",
                            message: e
                        });
                    });
            }
        }
        setShow(false);
    };

    return (<>
        <Card className="p-0 m-0">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <h5><Smartphone size={18} />&nbsp;App</h5>
                    {
                        user?.editTags && user?.role !== "Root" && !(user?.role === "User" && isSrl()) &&
                        (app
                            ? <><Button variant="danger" onClick={() => appUnlink(app.id)}>Unlink App</Button></>
                            : <><Button variant="primary" onClick={() => appLink()}>Link an App</Button></>
                        )
                    }
                </div>
                {
                    app && (user?.role === "Root"
                        ? <div className="mt-2">
                            <span>
                                Serial: <NavLink to={{ pathname: "/apps/edit", search: `?id=${app.id}` }}>{app.serial}</NavLink>
                            </span>
                        </div>
                        : <div className="mt-2">Serial: {app.serial}</div>
                    )
                }
                {
                    app &&
                    <div>Model: {app.model}</div>
                }
                {
                    !app &&
                    <div className="mt-2">TracLogik's tracking app records the movement of assets and people via bluetooth
                        tag devices. Associating an app with a person or item will automatically enable it for tracking through
                        any zones that are equipped with zone tags.
                    </div>
                }
            </Card.Header>
        </Card>
        <hr />
        <Modal show={showModal} size={curId ? `` : "lg"} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{`${curId ? "Unlink a" : "Link a"} Device`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {app && <span>Are you sure that you wish to unlink this device from {data?.compoundName}?</span>}
                {!app && <CommonTable btnClick={onOperate} ref={childRef} apiName="apps" columns={columns} />}
            </Modal.Body>
            <Modal.Footer>
                {app && <Button variant="danger" onClick={() => onOperate()}>Unlink</Button>}
                <Button variant="secondary" onClick={() => setShow(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showWarning} onHide={() => setShowWarning(null)}>
            <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure that you wish to link this device to {data?.compoundName}? This will unlink it
                    from {showWarning?.assetCompoundName || showWarning?.personCompoundName || showWarning?.zoneCompoundName}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onOperate("relink", showWarning)}>Link</Button>
                <Button variant="secondary" onClick={() => setShowWarning(null)}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    </>);
};

export default DeviceApp;
