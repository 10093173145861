import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import api from "../../api";

import AssetHasTag from "../../components/AssetHasTag";
import OverviewTab from "../../components/detail/OverviewTab";
import DeviceTracker from "../../components/detail/DeviceTracker";
import DeviceTag from "../../components/detail/DeviceTag";
import Notes from "../../components/detail/Notes";
import AssetDetails from "../../components/AssetDetails";
import { AlertTriangle } from "react-feather";
import CommonTable from "../../components/tables/CommonTable";
import * as queryString from "query-string";
import FavouriteModal from "../../components/favourite/FavouriteModal";
import LastLocation from "../../components/LastLocation";
import LastAttendance from "../../components/LastAttendance";
import Calendar from "../../components/calendar/Calendar";
import useAuth from "../../hooks/useAuth";
import AdminDetail from "./AdminDetail";
import Diagnostics from "../../components/detail/Diagnostics";
import NotyfContext from "../../contexts/NotyfContext";
import UploadImgModal from "../../components/UploadImgModal";
import AddExistingModal from "../rules/AddExistingModal";
import { blobToFile, compressPic, dataURLtoBlob, filterStorage } from "../../utils/staticMethods";
import RootTopBar from "../../components/navbar/RootTopBar";
import axios from "axios";
import WeatherBar from "../../components/WeatherBar";
import DeviceApp from "../../components/detail/DeviceApp";

const columns = [
  {
    Header: "Name",
    accessor: "ruleName",
    type: "link",
    link: "/rules/edit",
    stateId: "id"
  },
  {
    Header: "Type",
    accessor: "type"
  },
  {
    Header: "Status",
    accessor: "status",
    type: "ruleStatus"
  }
];
let interval;
let source1 = null;
const PeopleEdit = () => {
  const location = useLocation();
  const { id, direct } = queryString.parse(location.search);
  const [person, setPerson] = useState({});
  const [loadLastPosition, setLoadLastPosition] = useState(0);
  const [tab, setTab] = useState("overview");
  const [trackers, setTrackers] = useState(null);
  const [attendance, setAttendance] = useState(null);
  const [tags, setTags] = useState(null);
  const [apps, setApps] = useState(null);
  const [picture, setPicture] = useState(null);
  const [showUpload, setShowUpload] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const { user } = useAuth();
  const notify = useContext(NotyfContext);
  const navigate = useNavigate();
  const ruleRef = useRef();
  const [showRuleModal, setShowRuleModal] = useState(false);
  useEffect(() => {
    getPerson();
    getPci();
    if (direct) {
      setTab(direct);
    }
    interval && clearInterval(interval)
    interval = setInterval(() => {
      getPerson();
    }, 5000);
  }, [id]);

  useEffect(() => {
    return () => {
      setPerson({});
      setPicture(null)
      clearInterval(interval)
      if(source1) {
        source1.cancel('request canceled');
      }
    }
  }, []);

  const getPerson = () => {
    if(source1){
      source1.cancel('request canceled');
    }
    source1 = axios.CancelToken.source();
    api.get(`people/` + id, {
      cancelToken: source1.token
    })
      .then(res => {
        setPerson(res.data);
        if (res.data.lastPosition != null) {
          setLoadLastPosition(1);
        }
      })
  };

  useEffect(() => {
    if(person.id) {
      let obj = {
        link: location?.pathname,
        search: location?.search,
        name: `${person.compoundName} ${person.reference ? `(${person.reference})` : ""}`,
        icon: person.icon,
        id: person.id
      }
      filterStorage(obj)
    }
  }, [person])

  const getTrackerReady = (list) => {
    setTrackers(list);
  };

  const getTagReady = (list) => {
    setTags(list);
  };

  const getAppReady = (list) => {
    setApps(list);
  };

  const handleUpdateData = () => {
    getPerson();
  };

  const onTabChange = (e) => {
    setTab(e);
  };

  const onUpdatePerson = (data) => {
    setPerson(data);
    if (data.lastPosition != null) {
      setLoadLastPosition(1);
    }
  };

  const getPci = () => {
    api.get(`files/people/${id}/profilepic`, {
      responseType: "arraybuffer",
    }).then(res => {
      let blob = new Blob([res.data], { type: "img/jpeg" });
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      setPicture(url);
    }).catch(() => setPicture(null));
  };

  const onDelete = () => {
    api.delete(`files/people/${id}/profilepic`).then(() => {
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      setPicture(null);
      setShowUpload(false);
    });
  };

  const onUpload = () => {
    if (!uploadFile || uploadFile.length === 0) return;
    let reader = new FileReader();
    reader.onload = function (evt) {
      let replaceSrc = evt.target.result;
      compressPic(replaceSrc, base => {
        let blob = dataURLtoBlob(base)
        let miniFile = blobToFile(blob, 'new' + uploadFile[0].name, uploadFile[0].type)
        let data = new FormData();
        data.append("uploadedFile", miniFile);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };
        api.post(`files/people/${id}/profilepic`, data, config).then(() => {
          notify.open({
            type: "success",
            message: "Changes Saved"
          });
          setShowUpload(false);
          getPci();
        }).catch(err => {
          notify.open({
            type: "error",
            message: err
          });
        });
      });
    };
    reader.readAsDataURL(uploadFile[0]);
  };

  return (
    <React.Fragment>
      <Helmet defer={false}  title={person.compoundName || "People"} />
      <RootTopBar data={person} />
      <Container fluid className="p-0">
        {person.compoundName &&
        <h1 className="h3 mb-3">{`${person.compoundName} ${person.reference ? `(${person.reference})` : ""}`}
          <FavouriteModal id={person.id} type="people" /></h1>}
        <Row>
          <Col md="4" xl="3">
            {picture && <div className="p-3 bg-white border-bottom">
              <img alt="profile" className="w-100 cursor-pointer" src={picture} onClick={() => {
                if(user?.editPeople || user?.id === id) {
                  setShowUpload(true)
                }
              }} />
            </div>}
            {loadLastPosition ? (<LastLocation profile={person} apiName="people"  />) : null}
            {person.lastAttendance ? (<LastAttendance profile={person} />) : null}
            {loadLastPosition ? (<WeatherBar data={person.lastPosition} />) : null}
            <AssetHasTag apiName="people" handleUpdateData={handleUpdateData} id={id} hashTags={person.hashTags} />
            {!picture && (user?.editPeople || user?.id === id) && user?.role !== 'Root' &&  <div className="border-top p-3 bg-white">
              <span className="text-primary cursor-pointer"
                    onClick={() => setShowUpload(true)}>Add Profile Picture</span>
            </div>}
          </Col>
          <Col md="8" xl="9" className="mt-3 mt-sm-0">
            <div className="tab">
              <Tab.Container id="left-tabs-example" activeKey={tab} onSelect={(e) => onTabChange(e)}>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="details">Details</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="devices">Devices</Nav.Link>
                  </Nav.Item>
                  {user?.role !== 'Root' && <Nav.Item>
                    <Nav.Link eventKey="calendar">Calendar</Nav.Link>
                  </Nav.Item>}
                  <Nav.Item>
                    <Nav.Link eventKey="diagnostics">Diagnostics</Nav.Link>
                  </Nav.Item>
                  {user?.role !== 'Root' && <Nav.Item>
                    <Nav.Link eventKey="rules">Rules</Nav.Link>
                  </Nav.Item>}
                  {user?.viewNotes && user?.role !== 'Root' && <Nav.Item>
                    <Nav.Link eventKey="notes">Notes</Nav.Link>
                  </Nav.Item>}
                  {user?.role === "Admin" && (person.role === 'User' || person.role === null) && <Nav.Item>
                    <Nav.Link eventKey="access">System Access</Nav.Link>
                  </Nav.Item>}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <OverviewTab onGetAttendance={(e) => setAttendance(e)} apiName={"people"} data={person} trackers={trackers} tab={tab} id={id} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="details">
                    {person.icon &&
                    <AssetDetails asset={person} type="people" setAsset={onUpdatePerson} apiName="people" />}
                  </Tab.Pane>
                  {user?.role === "Admin"  && <Tab.Pane eventKey="access">
                    <AdminDetail setPerson={() => getPerson()} data={person} />
                  </Tab.Pane>}
                  <Tab.Pane eventKey="devices">
                    <DeviceTracker id={id} getTrackerReady={getTrackerReady} type="people" data={person}/>
                    <DeviceTag getTagReady={getTagReady} id={id} type="people" data={person} />
                    <DeviceApp getAppReady={getAppReady} id={id} type="people" data={person} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="calendar">
                    {person.id && user?.role !== 'Root' && <Calendar data={person} id={person.id} tab={tab} attendance={attendance} type={'people'}/>}
                  </Tab.Pane>
                  <Tab.Pane eventKey="diagnostics">
                    {person.id && <Diagnostics root tags={tags} trackers={trackers} apps={apps} id={id} type={1} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="rules">
                    <div className="d-flex justify-content-between mb-3">
                      <h4 className=""><AlertTriangle size={18} /> Rules</h4>
                      <div>
                        {(user?.editPeople || user?.id === id) && <Button onClick={() => setShowRuleModal(true)} className="me-1" variant="success">Add to Existing</Button>}
                        {(user?.editPeople || user?.id === id) && user?.createRules &&<Button onClick={() => navigate(`/rules/create?link=people&linkId=${id}`)}>Create New</Button>}
                      </div>
                    </div>
                    <CommonTable ref={ruleRef} apiName="rules" parentId={id} columns={columns} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="notes">
                    <Notes id={id} type="people" />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      <AddExistingModal onHide={() => setShowRuleModal(false)} show={showRuleModal}  apiName={'people'} dataId={id} onUpdatedRules={() => {ruleRef.current.updateTable(1); setShowRuleModal(false)}}/>

      <UploadImgModal setUploadFile={(e) => setUploadFile(e)} showUpload={showUpload} onDelete={onDelete}
                      onHide={() => setShowUpload(false)} picture={picture} onUpload={onUpload} />
    </React.Fragment>
  );
};

export default PeopleEdit;
